<template>
    <el-scrollbar class="second-bar">
        <div class="second-bar-inside-box" @click="scrollToCenter">
            <div class="padding-120"></div>
            <template v-for="item of items">
                <router-link
                    v-if="item.text === 'User'"
                    draggable="false"
                    :class="['menu-item', computedClass(item)]"
                    :key="item.text"
                    :to="{ path: item.path }"
                    v-permission="['premium']">
                    {{ item.text }}
                </router-link>
                <router-link
                    draggable="false"
                    v-else
                    :class="['menu-item', computedClass(item)]"
                    :key="item.text + '-else'"
                    :to="{ path: item.path }">
                    {{ item.text }}
                </router-link>
            </template>
            <div class="padding-120"></div>
        </div>
    </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'HomeSecondBar',
    computed: {
        ...mapGetters(['user']),
        active() {
            return this.$route.query.tab || 'dashboard';
        },
    },
    data() {
        return {
            items: [
                { path: '/dashboard', text: 'Dashboard' },
                { path: '/assets', text: 'Assets' },
                { path: '/map', text: 'Map' },
                { path: '/connect', text: 'Connect' },
                { path: '/ai', text: 'A.I.' },
                { path: '/shadow', text: 'Shadow' },
                { path: '/app', text: 'App' },
                { path: '/flow', text: 'Flow' },
                { path: '/ruleflow', text: 'RuleFlow' },
                { path: '/user', text: 'User' }
            ]
        };
    },
    methods: {
        isActive(path) {
            if (this.$route.path == '/' && path === '/dashboard') {
                return true;
            }
            return this.$route.path.startsWith(path);
        },
        computedClass(item) {
            return {
                'selected': this.isActive(item.path),
            }
        },
        scrollToCenter() {
            this.$nextTick(() => {
                const el = this.$el.querySelector('.selected');
                if (el) {
                    const scroll = this.$el.querySelector('.el-scrollbar__wrap');
                    scroll.scrollLeft = el.offsetLeft - scroll.offsetWidth / 2 + el.offsetWidth / 2;
                }
            });
        }
    },
    mounted() {
        if (this.user && this.user.plan === 'premium') {
            this.items.push();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* *********************************************************  second-bar  **************************************************************************** */

.second-bar {
    background: #3C4556;
    height: 56px;
    overflow: hidden;
    position: relative;
}

.second-bar ::v-deep .el-scrollbar__wrap {
    overflow-y: auto;
    overflow-x: hidden;
}

.second-bar-inside-box {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.second-bar-inside-box>* {
    flex-shrink: 0;
}

.padding-120 {
    width: 120px;
}

a {
    text-decoration: none;
}

.menu-item {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 130px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
}

.selected {
    border-bottom: 2px solid #FCB900;
    color: #FCB900;
}
</style>