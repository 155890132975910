import axios from "axios";
import { EventBus } from "./event-bus.js";

import SockJS from "sockjs-client";
import Stomp from "stompjs";

// const API_URL_BASE = 'http://localhost:8080/v1';
// const API_URL_BASE = 'http://localhost:30080/v1';
// const API_URL_BASE = 'http://10.10.21.28:30080/v1';
// const API_URL_BASE = 'http://localhost:1236/v1';
// const API_URL_BASE = 'https://dev-api.cereb.ai/v1';
const API_URL_BASE = process.env.VUE_APP_API_URL_BASE;

// const API_URL_BASE = 'http://platform-demo-v2.cereb.ai/#/';
// const TIMEZONE = 'Asia/Hong_Kong'

const instance = axios.create({
  baseURL: API_URL_BASE,
  timeout: 60000,
  withCredentials: true,
});

export const requestInterceptor = (config) => {
  // 请求时将token加入到headers
  if (localStorage.token) {
    config.headers["Authorization"] = "Bearer " + localStorage.token;
  }
  return config;
};

export const requestErrorInterceptor = (error) => {
  console.log("request err: " + error);
  return Promise.reject(error);
};

export const responseInterceptor = (response) => {
  if ((response.status === 400 || response.status === 403) && response.data) {
    throw response.data;
  }
  return response.data;
};

export const responseErrorInterceptor = (error) => {
  if (error && error.response && error.response.status === 401) {
    // 401 清除localStorage中的token
    // localStorage.removeItem('token');
    // 跳转登录页
    EventBus.$emit("logout");
    return Promise.reject("unauthorized");
  }
  console.log("response err: " + error);
  return Promise.reject(error);
};

// ==========>请求拦截
instance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
// ==========>响应拦截
instance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

// function responseHandling(response) {
//     if (response.status === 200) {
//         return response.data
//     } else {
//         throw 'error'
//     }
// }

// function defaultErrorHanding(err) {
//     console.log(err)
//     if (err && err.response) {
//         if (err.response.status === 401) {
//             // 401 清除localStorage中的token
//             localStorage.removeItem('token');
//             throw 'unauthorized'
//         } else if ((err.response.status === 400 || err.response.status === 403) && err.response.data) {
//             throw err.response.data
//         }
//     }
//     throw 'error'
// }

// function getAccessToken() {
//     if (!localStorage.token) {
//         throw 'unauthorized'
//     }
//     return localStorage.token
// }

// function getAuthConfig(token) {
//     return {
//         headers: {
//             'Authorization': 'Bearer ' + token
//         }
//     }
// }

export function login(username, password) {
  return instance.post("/login/username", {
    username: username,
    password: password,
  });
}

export function loginEmail(email, password) {
  return instance.post("/login/email", {
    email: email,
    password: password,
  });
}

export function verifyLogin(msgId, verificationCode) {
  let params = {
    msgId: msgId,
    verificationCode: verificationCode,
  };
  return instance.post("/login/verify_verification_code", null, {
    params: params,
  });
}

export function updateVerifyLogin(userDetailVo) {
  let params = {
    verifyLogin: userDetailVo.verifyLogin,
  };
  return instance.post(
    "/user/detail/" + userDetailVo.id + "/verify_login",
    null,
    {
      params: params,
    }
  );
}

export function signup(username, email, password) {
  return instance.post("/signup/username", {
    username: username,
    email: email,
    password: password,
  });
}

export function resetPasswordRequest(email) {
  return instance.post("/password/request_reset", {
    email: email,
  });
}

export function resetPassword(email, newPassword, confirmPassword, code) {
  return instance.post("/password/reset", {
    email: email,
    code: code,
    newPassword: newPassword,
    confirmPassword: confirmPassword,
  });
}

export function getUserDetail() {
  return instance.get("/user/detail/");
}

export function getUserWidgets() {
  return instance.get("/dashboard/widgets");
}

export function getUserWidgetByWidgetId(widgetId) {
  return instance.get("/dashboard/widgets" + widgetId);
}

export function getEvents(params) {
  // if (type == '' && sinceTime == '') {
  //     return instance.get('/dashboard/events')
  // } else if (type !== '' && sinceTime == '') {
  //     let params = {
  //         type: type,
  //     }
  return instance.get("/dashboard/events", {
    params,
  });

  // } else if (type == '' && sinceTime !== '') {
  //     let params = {
  //         sinceTime: sinceTime
  //     }
  //     return instance.get('/dashboard/events', {
  //         params
  //     })

  // } else if (type !== '' && sinceTime !== '') {
  //     let params = {
  //         type: type,
  //         sinceTime: sinceTime
  //     }
  //     return instance.get('/dashboard/events', {
  //         params
  //     })

  // }
}

export function createAsset(assetVo) {
  return instance.post("/assets", {
    assetVo: assetVo,
  });
}

export function getAllAssetVo() {
  return instance.get("/assets");
}

export function deleteAsset(assetId) {
  return instance.delete("/assets" + assetId);
}

// 地图上新增设备
export const setAssetAttr = (assetId, params) => {
  return instance.post(`/assets/${assetId}/attr`, params);
};

// 地图上删除设备
export const deleteAssetAttr = (assetId, params) => {
  // https://masteringjs.io/tutorials/axios/delete-with-body
  // https://stackoverflow.com/questions/51069552/axios-delete-request-with-request-body-and-headers
  return instance.delete(`/assets/${assetId}/attr`, { data: params });
};

export function getDevicesDataByAssetIds(assetIds) {
  let params = {
    // assetId以逗号分隔
    assetIds: assetIds,
  };
  return instance.get("/assets/assets_data", {
    params,
  });
}

// function addConnect(connect) {
//     let token = getAccessToken()
//     return instance.post('/connects',
//     {
//         connect: connect
//     },
//     getAuthConfig(token)
//     ).then(responseHandling)
//     .catch(defaultErrorHanding)
// }
export function getAllConnect() {
  return instance.get("/connects");
}

export function cmdConnectInstance(instanceId, cmd) {
  return instance.put(
    "/connects/connect_instances/" + instanceId + "/cmd/" + cmd
  );
}

export function createConnectInstance(connectInstanceVo) {
  let stopped = false;
  if (
    connectInstanceVo &&
    connectInstanceVo.stopped &&
    connectInstanceVo.stopped === true
  ) {
    stopped = true;
  }
  return instance.post(
    "/connects/" + connectInstanceVo.connectId + "/instances",
    {
      name: connectInstanceVo.name,
      stopped: stopped,
      attr: connectInstanceVo.attr,
    }
  );
}

export function updateConnectInstance(
  instanceId,
  connectInstanceVo,
  deleteAssets
) {
  let params = {
    deleteAssets: deleteAssets,
  };
  return instance.put(
    "/connects/" + connectInstanceVo.connectId + "/instances/" + instanceId,
    {
      instanceId: instanceId,
      name: connectInstanceVo.name,
      connectId: connectInstanceVo.connectId,
      attr: connectInstanceVo.attr,
    },
    {
      params: params,
    }
  );
}

export function confirmConnectInstance(connectInstanceVo, selectedDevices) {
  return instance.post(
    "/connects/" +
      connectInstanceVo.connectId +
      "/instances/" +
      connectInstanceVo.instanceId +
      "/confirm",
    selectedDevices
  );
}

export function getConnectInstanceListByConnectId(connectId) {
  return instance.get("/connects/" + connectId + "/instances");
}

export function getConnectInstanceVo(connectId, instanceId) {
  return instance.get("/connects/" + connectId + "/instances/" + instanceId);
}

export function deleteConnectInstance(connectId, instanceId) {
  return instance.delete("/connects/" + connectId + "/instances/" + instanceId);
}

export function check_salto_credential(salto_username, salto_password) {
  let params = {
    salto_username: salto_username,
    salto_password: salto_password,
  };
  return instance.get("/salto/integration/check_credential", {
    params,
  });
}

export function list_sites(salto_username, salto_password) {
  let params = {
    salto_username: salto_username,
    salto_password: salto_password,
  };
  return instance.get("/salto/integration/sites", {
    params,
  });
}

export function list_IQs(salto_username, salto_password, site_id) {
  let params = {
    salto_username: salto_username,
    salto_password: salto_password,
  };
  return instance.get(
    "/salto/integration/sites/" + unescape(site_id) + "/iqs",
    {
      params,
    }
  );
}

export function get_salto_iq_secret(
  salto_username,
  salto_password,
  site_id,
  iq_id,
  otp
) {
  let params = {
    salto_username: salto_username,
    salto_password: salto_password,
    site_id: site_id,
    iq_id: iq_id,
    otp: otp,
  };
  return instance.get("/salto/integration/get_iq_secret", {
    params,
  });
}

export function check_uhoo_credential(uhoo_client_id) {
  let params = {
    client_id: uhoo_client_id,
  };
  return instance.get("/uhoo/integration/check_credential", {
    params,
  });
}

export function getLifeSmartUrl() {
  return instance.get("/integration/lifesmart/get_auth_url");
}

export function getSonosUrl() {
  return instance.get("/sonos/integration/get_auth_url");
}
export function getSonosHouseholds(state) {
  return instance.get("/sonos/integration/households", {
    params: { state: state },
  });
}

// function getUserInfo(messageId){
//     let params = {
//         messageId: messageId
//     }
//     return instance.get('/public/integration/lifesmart/get_info', {
//         params
//     })
// }

export function webSocketClient(websocketUrl) {
  let url = API_URL_BASE + websocketUrl;
  let socket = new SockJS(url);
  return Stomp.over(socket);
}

export function check_coolautomation_credential(
  coolautomation_username,
  coolautomation_password
) {
  let params = {
    coolautomation_username: coolautomation_username,
    coolautomation_password: coolautomation_password,
  };
  return instance.get("/coolautomation/integration/check_credential", {
    params,
  });
}

export function check_airthongs_credential(
  airthings_client_id,
  airthings_client_secret
) {
  let params = {
    airthings_client_id: airthings_client_id,
    airthings_client_secret: airthings_client_secret,
  };
  return instance.get("/airthings/integration/check_credential", {
    params,
  });
}

export function check_kuju_credential(kuju_username, kuju_password) {
  let params = {
    kuju_username: kuju_username,
    kuju_password: kuju_password,
  };
  return instance.get("/kuju/integration/check_credential", {
    params,
  });
}

export function get_kuju_gateway_sn(kuju_username, kuju_password) {
  let params = {
    kuju_username: kuju_username,
    kuju_password: kuju_password,
  };
  return instance.get("/kuju/integration/gateway_sn", { params });
}

export function check_moodo_credential(moodo_email, moodo_password) {
  let params = {
    moodo_email: moodo_email,
    moodo_password: moodo_password,
  };
  return instance.get("/moodo/integration/check_credential", {
    params,
  });
}

// #region tuya
export function check_tuya_credential(tuyaAccessId, tuyaAccessSecret) {
  let params = {
    tuya_access_id: tuyaAccessId,
    tuya_access_secret: tuyaAccessSecret,
  };
  return instance.get("/tuya/integration/check_credential", {
    params,
  });
}
// #endregion

export function checkAciistExistDevice(connectInstanceVo) {
  let params = {
    connectInstanceVo: connectInstanceVo,
  };
  return instance.get("/aciist/integration/check_device_exist", {
    params,
  });
}

// 获取二维码
export function getShadowQrcode(id, title = "") {
  return instance.get(`/virtual_controller/${id}/qrcode`, {
    responseType: "blob",
    params: {
      title: title,
    },
  });
  // return API_URL_BASE + '/virtual_controller/' + id + '/qrcode/' + type
}

// 创建-->返回二维码
export function createVirtualController(data, logo) {
  var form = new FormData();
  form.append("json", JSON.stringify(data));
  form.append("logo", logo);
  return instance.post("/virtual_controller", form, {
    "Content-Type": "multipart/form-data;charse=UTF-8",
  });
}

// 查询-->返回所有二维码信息
export function getVirtualControllerList(type) {
  return instance.get("/virtual_controller/" + type);
}

// 查询-->返回单个二维码Logo
export function getVirtualControllerLogo(id) {
  return API_URL_BASE + "/public/virtual_controller/" + id + "/logo";
  // return instance.get('/public/virtual_controller/' + id + '/logo')
}

// 删除-->返回所有二维码信息
export function deleteVirtualController(id) {
  return instance.delete("/virtual_controller/" + id);
}

// 编辑-->返回成功失败
export function editVirtualController(id, data, logo) {
  var form = new FormData();
  form.append("json", JSON.stringify(data));
  form.append("logo", logo);
  return instance.post("/virtual_controller/" + id, form, {
    "Content-Type": "multipart/form-data;charse=UTF-8",
  });
}

export function createMqttShadowInstance(name) {
  let params = {
    shadowInstanceName: name,
  };
  return instance.post(
    "/virtual_controller/sub/mqtt_shadow/instance",
    {},
    {
      params: params,
    }
  );
}

export function getMqttShadowInstances() {
  return instance.get("/virtual_controller/sub/mqtt_shadow/instance");
}

export function deleteMqttShadowInstance(shadowInstanceId, mqttUsername) {
  let params = {
    shadow_instance_id: shadowInstanceId,
    mqtt_username: mqttUsername,
  };
  return instance.delete("/virtual_controller/sub/mqtt_shadow/instance", {
    params: params,
  });
}

export function createWebsocketShadowInstance(name) {
  let params = {
    shadowInstanceName: name,
  };
  return instance.post(
    "/virtual_controller/sub/websocket_shadow/instance",
    {},
    {
      params: params,
    }
  );
}

export function getWebsocketShadowInstances() {
  return instance.get("/virtual_controller/sub/websocket_shadow/instance");
}

export function deleteWebsocketShadowInstance(shadowInstanceId) {
  return instance.delete(
    "/virtual_controller/sub/websocket_shadow/instance/" + shadowInstanceId
  );
}

export function triggerEventAnalysis() {
  return instance.post("/dashboard/trigger_event_analysis");
}

export function controlDevice(assetId, command) {
  return instance.post("/assets/" + assetId + "/control_device", command);
}

/**
 * 新建对话
 */
export function newConversation(agents = [], promptPara) {
  const data = {
    agents: agents,
    platform: "web",
  };

  if (promptPara) {
    data["prompt_para"] = promptPara;
  }

  return instance.post("/conversations/v2", data);
}

/**
 * 发送消息
 */
export function sendMessage(conversationID, message) {
  const token = localStorage.token;
  return instance.post(
    `/conversations/v2/${conversationID}/messages`,
    message,
    {
      headers: {
        "user-cookie": `access-token=${token}`, //增加了一个user-cookie
      },
      params: {
        conversationID,
      },
    }
  );
}

/**
 * 获取消息
 */
export function getMessage(conversationID, sequenceNumber) {
  return instance.get(
    `/conversations/v2/${conversationID}/messages/${sequenceNumber}`
  );
}

export function getUserFromSession() {
  const json = sessionStorage.getItem("user");
  if (!json) {
    return null;
  }
  return JSON.parse(json);
}

/**
 * 删除对话
 */
export function deleteConversation(conversationID) {
  return instance.delete(`/conversations/${conversationID}`);
}

export function getRuleById(ruleId) {
  const userId = getUserFromSession().id;
  return instance.get(`/rule`, {
    params: { id: ruleId, "user-id": userId, _t: new Date().getTime() },
  });
}

export function getRules() {
  const userId = getUserFromSession().id;
  return instance.get(`/rules`, {
    params: {
      _t: new Date().getTime(),
      "user-id": userId,
    },
  });
}

export function addOrUpdateRule(rule, ruleId) {
  const userId = getUserFromSession().id;
  return instance.post(
    `/rule/addOrUpdate
`,
    rule,
    {
      params: {
        "user-id": userId,
        id: ruleId,
      },
    }
  );
}

export function deleteRule(ruleId) {
  const userId = getUserFromSession().id;
  return instance.post(`/rule/delete`, null, {
    params: {
      "user-id": userId,
      id: ruleId,
    },
  });
}

export function getAdminPlatformUsers(status, offset, limit) {
  let params = {
    status: status,
    offset: offset,
    limit: limit,
  };
  return instance.get("/admin/adminPlatform/users", {
    params,
  });
}

export function updateUserDetail(userDetail) {
  return instance.post("/admin/adminPlatform/users", userDetail);
}

export function updateStatus(id, status) {
  let params = {
    status: status,
  };
  return instance.post("/admin/users/" + id + "/status", {}, { params });
}

export function deleteUser(id) {
  return instance.delete("/admin/adminPlatform/users/" + id);
}

/**获取账号列表 */
export async function getUserAccounts({
  organizationId = "cereb-RLk6kY",
  name,
  siteId,
  offset,
  limit,
} = {}) {
  organizationId = getUserFromSession().organizationId || organizationId;
  const result = await instance.get(`/fission/auth-center/users`, {
    params: {
      organizationId,
      name,
      siteId,
      offset,
      limit,
    },
  });
  return result["data"];
}

/** 创建用户账号 */
export async function createOrUpdateUserAccount(user) {
  //  {
  //   "username": "Shawn",
  //   "alias": "Shawn",
  //   "role": "staff",
  //   "email": "1272172856@qq.com",
  //   "organizationId": "cereb-RLk6kY",
  //   "siteId": "cereb-RLk6kY-site-thtbMN",
  //   "status": "active",
  //   "dataPoints": "200",
  //   "plan": "premium"
  //  }
  const organizationId = getUserFromSession().organizationId;
  user["organizationId"] = organizationId;
  return instance.post(`/fission/auth-center/users`, [user]);
}

/** 删除用户账号 */
export async function deleteUserAccount(userId) {
  return instance.delete(`/fission/auth-center/users`, {
    params: {
      userId,
    },
  });
}

/** 获取全部门店 */
export async function getAllSites() {
  const organizationId = getUserFromSession().organizationId;
  const result = await instance.get("/fission/auth-center/sites", {
    params: {
      organizationId,
      offset: 1,
      limit: Number.MAX_SAFE_INTEGER,
    },
  });
  return result["data"]["list"] || [];
}

/** 创建或更新site */
export async function createOrUpdateSite(site) {
  //{
  //   "name": "hongkong-office",
  //   "address": "1, Dapu, Hongkong",
  //   "organizationId": "cereb-RLk6kY"
  //}
  const organizationId = getUserFromSession().organizationId;
  site["organizationId"] = organizationId;
  return instance.post("/fission/auth-center/sites", site);
}

/** 删除site */
export async function deleteSite(siteId) {
  return instance.delete(`/fission/auth-center/sites`, {
    params: {
      siteId,
    },
  });
}

export async function getUserOrganizationByEmail(userEmail) {
  const result = await instance.get("/fission/auth-center/organizations", {
    params: {
      userEmail,
    },
  });
  return result["organization"];
}
