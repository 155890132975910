<template>
    <div class="top-bar">
        <div class="top-bar-inside-box">
            <div class="flex-box">
                <img class="logo" src="../assets/logo.png" @click="homeClick">
                <b class="app-name" @click="homeClick">Cereb Platform</b>
            </div>
            <div class="flex-box">
                <a class="setting-hyperlink" href="javascript:void(0)" @click="settingClick">Setting</a>
                <a class="log-out-hyperlink" href="javascript:void(0)" @click="logoutClick">Log Out</a>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MessageBox
} from 'element-ui';
import {
    EventBus
} from "../event-bus.js";

export default {
    name: 'HomeTopBar',
    props: {
        msg: String
    },
    methods: {
        async logoutClick() {
            await MessageBox.confirm(
                'Are you sure you want to log out?', {
                confirmButtonText: 'Log Out',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });
            EventBus.$emit('logout');
        },
        settingClick() {
            this.$router.push({
                'name': 'setting'
            })
        },
        homeClick() {
            this.$router.push({
                'name': 'home'
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* *********************************************************  top-bar  **************************************************************************** */
.top-bar {
    display: flex;
    height: 66px;
    background: #171D20;
    box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
}

.top-bar-inside-box {
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    padding: 0 120px;
    justify-content: space-between;
    align-items: center;
    /*width: 1200px;*/
    height: 66px;
}

.logo {
    /*margin-top: 14px;*/
    width: 38px;
    height: 38px;
    /*cursor:pointer;*/
}

.flex-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.app-name {
    display: flex;
    flex-direction: row;
    left: 46px;
    /*        margin-left: 12px;
        margin-top: 19px;
		cursor:pointer;
*/
    height: 66px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 66px;
    white-space: nowrap;
    overflow: hidden;
}

.setting-hyperlink {
    height: 66px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 66px;
}

.log-out-hyperlink {
    text-align: right;
    display: inline-block;
    /*        margin-left: 1116px;
        margin-top: 21px;
*/
    width: 80px;
    height: 66px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 66px;
}

a {
    text-decoration: none;
}
</style>