<template>
  <!-- user 在右, bot 在左 -->
  <div v-if="source.isUser" class="right-message">
    <img src="../../assets/ChatGPT/user.png" alt="">
    <div>
      <div class="time">{{ kFormatTime(source.createdTime) }}</div>
      <div class="content-wrapper">
        <img v-if="source.hasError" class="error" src="../../assets/ChatGPT/error.png" alt="">
        <div class="content">{{ source.content }}</div>
      </div>
    </div>
  </div>
  <div v-else class="left-message">
    <div class="image-wrapper">
      <img src="../../assets/ChatGPT/bot.png" alt="">
    </div>
    <div>
      <div class="time">{{ kFormatTime(source.createdTime) }}</div>
      <!-- 如果内容为空, 则处于加载状态 -->
      <!-- https://codepen.io/david_janke/pen/VxppEr -->
      <div v-if="!source.content.length" class="content loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div v-else class="content" ref="contentRef">{{ source.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/utils/timeUtil';
export default {
  data() {
    return {
      resizeObserver: null,
    };
  },
  components: {
  },
  props: {
    // 使用 vue-virtual-scroll-list 时
    // 其组件 props 名必须为 source
    source: {
      type: Object,
      required: true,
      validator(value) {
        return 'isUser' in value && 'createdTime' in value && 'content' in value;
      }
    },
  },
  methods: {
    kFormatTime: formatTime,
    onComplete() {
      // console.log('onComplete');
      this.$store.commit('updateBotMessage', { id: this.source.id, needsAnimation: false });
      window.messageListRef.scrollToOffset(window.messageListRef.getScrollSize());
    },
  },
  computed: {
    // 是否需要打字机动画
    // 当动画执行一次完成后, 不再需要打字机动画
    needsAnimation() {
      return this.$store.state.botMessage[this.source.id];
    },
  },
  watch: {
    source: {
      handler(newSource, oldSource) {
        if (!oldSource.length && !newSource.isUser && newSource.content.length) {
          this.resizeObserver = new ResizeObserver(() => {
            window.messageListRef.scrollToOffset(window.messageListRef.getScrollSize());
          });
          this.$nextTick(() => {
            this.resizeObserver.observe(this.$refs.contentRef);
          });
        }
      },
      deep: true,
    },
  }
}
</script>

<style scoped>
.left-message .content.loading {
  width: 40px;
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.loading span {
  width: 3px;
  height: 3px;
  display: inline-block;
  border-radius: 50%;
  background-color: #040405;
  animation: three_dot_loading 1.5s infinite ease-in-out both;
}

/* .loading span:nth-child(1) {} */

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes three_dot_loading {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}


/* image-wrapper img */
.image-wrapper {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid #D2D2D2;
}

.left-message .image-wrapper {
  margin-right: 8px;
}

.left-message img {
  width: 21px;
  height: 21px;
  margin: 5px;
}

.right-message img {
  width: 31px;
  height: 31px;
  margin-left: 8px;
  border-radius: 50%;
  border: 1px solid #D2D2D2;
}

.left-message {
  display: flex;
  margin-bottom: 27px;
  line-height: 22px;
}

.right-message {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 27px;
  line-height: 22px;
}

.content-wrapper {
  display: flex;
  align-items: center;
}

.right-message img.error {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 0 12px 0 0;
}

/* time */
.time {
  font-size: 12px;
  font-weight: 400;
  color: #A8A8A8;
  margin-bottom: 7px;
}

.right-message .time {
  text-align: right;
}

/* content */
.left-message .content {
  color: #040405;
  background-color: #F6F7F9;
  border-radius: 4px;
  width: 267px;
  box-sizing: border-box;
  padding: 12px 16px 7px;
  /* https://caniuse.com/css-text-wrap-balance */
  /* text-wrap: balance; */
}

.right-message .content {
  color: #040405;
  background-color: #D2F9D1;
  border-radius: 4px;
  width: 245px;
  text-align: left;
  box-sizing: border-box;
  padding: 11px 12px;
  /* text-wrap: balance; */
}
</style>