<template>
  <transition name="dialog-fade">
    <div v-if="show" class="dialog-overlay" @click="onClickModal">
      <div class="container" v-loading="loading" ref="containerRef">
        <header>Cereb Assistant
          <img src="../../assets/ChatGPT/close.png" alt="" @click="onClose">
        </header>
        <div class="content">
          <!-- https://tangbc.github.io/vue-virtual-scroll-list/#/dynamic-size -->
          <!-- https://github.com/tangbc/vue-virtual-scroll-list/blob/master/example/src/views/dynamic-size/Main.vue -->
          <virtual-list class="message-list scrollbar" :data-key="'id'" :data-sources="messageList" :data-component="messageComponent" :estimate-size="10" ref="messageListRef" />
          <div class="bottom-input">
            <input type="text" @keydown="onKeydown" v-model="inputValue" ref="inputRef">
            <div class="submit-button" @click="onSubmit">
              <img src="../../assets/ChatGPT/submit.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { v4 as uuidV4 } from 'uuid';
import { newConversation, sendMessage, getMessage, webSocketClient } from '../../api';
import VirtualList from 'vue-virtual-scroll-list';
import message from './message.vue';

export default {
  data() {
    return {
      // 输入框文本
      inputValue: '',
      // 对话信息
      messageList: [],
      messageComponent: message,
      // 对话 ID
      // conversationId: '',
      // 是否正在加载
      loading: false,
      // 定时器
      timer: null,
      // 接收消息定时器
      receiveMessageTimer: null,
      // 是否禁止输入
      enableInput: true,
      isWorkflow: false,
      stompClient: null,
    };
  },
  computed: {
    conversation: {
      get() {
        return this.$store.state.conversation;
      },
      set(value) {
        this.$store.commit('setConversation', value);
      },
    },
    conversationId() {
      return this.conversation && this.conversation.conversationId;
    },
    websocketId() {
      return this.conversation && this.conversation.websocketId;
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          // 自动聚焦至输入框
          this.$refs.inputRef.focus();
          window.messageListRef = this.$refs.messageListRef;
          document.addEventListener("keydown", this.handleESC);
        })

      } else {
        document.removeEventListener("keydown", this.handleESC);
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    VirtualList,
    // message,
  },
  methods: {
    saveMessageList() {
      this.$store.dispatch('saveMessages', this.messageList);
    },
    /**
     * 在输入框中按下 enter 键, 发送消息
     */
    onKeydown(event) {
      if (event.key == 'Enter') {
        this.onSubmit();
        // 手动取消聚焦
        // this.$refs.inputRef.blur();
      }
    },
    onClickModal(event) {
      if (event.target.classList.contains('dialog-overlay')) {
        this.onClose();
      }
    },
    /**
     * 关闭对话
     */
    onClose() {
      this.saveMessageList();
      this.$emit('update:show', false);
      this.clearTimer();
      // this.$store.commit('clearBotMessage');
      document.removeEventListener('keydown', this.handleESC);

      if (this.conversationId !== '') {
        // 删除当前对话
        //deleteConversation(this.conversationId);
      }
    },
    /**
     * 清除计时器
     */
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    /**
     * 锁定输入
     */
    lockInput() {
      this.enableInput = false;
    },
    /**
     * 解锁输入
     */
    unlockInput() {
      // 当出错或未拉取到回复消息时
      // 删除当前加载态消息
      if (!this.messageList.length &&
        !this.messageList[this.messageList.length - 1].isUser &&
        this.messageList[this.messageList.length - 1].content === '') {
        this.messageList[this.messageList.length - 2].hasError = true;
        this.messageList.pop();

        this.$store.commit('removeBotMessage', this.messageList[this.messageList.length - 1].id);
      }
      this.enableInput = true;
    },
    async initConversation() {
      // this.loading = true;
      try {
        const res = await newConversation();
        // this.loading = false;
        this.conversation = res.conversation;
      } catch (error) {
        // this.loading = false;
        this.$message.error('Error');
      }
    },
    // https://github.com/tangbc/vue-virtual-scroll-list/tree/master#public-methods
    // https://github.com/tangbc/vue-virtual-scroll-list/blob/master/example/src/views/chat-room/Main.vue#L172-L176
    scrollListToEnd() {
      this.$nextTick(() => {
        if (this.$refs.messageListRef && this.$refs.messageListRef.scrollToOffset) {
          this.$refs.messageListRef.scrollToOffset(this.$refs.messageListRef.getScrollSize());
        }
      })
    },
    async handleSendMessage(message) {
      const { userMessage } = this.addMessage(message);
      await this.createStompClient();
      const sendMessageResponse = await sendMessage(this.conversationId, { content: message });
      userMessage.sequenceNumber = sendMessageResponse.message.sequenceNumber;
      this.remedyMessage(message, userMessage.sequenceNumber);
    },
    async createStompClient() {
      const me = this;
      if (this.stompClient) {
        return Promise.resolve(this.stompClient)
      }
      // promise
      let handleResolve;
      let handleReject;
      const promise = new Promise((resolve, reject) => {
        handleResolve = resolve
        handleReject = reject
      })
      const stompClient = webSocketClient('/ws/shadow_websocket')
      const destination = '/user/topic/shadow_websocket'
      const headers = {
        'cereb-websocket-id': this.websocketId,
        'cereb-websocket-path': `/${this.conversationId}`,
        "access-token": localStorage.token
      }

      stompClient.connect(headers, () => {
        handleResolve(stompClient)
        stompClient.subscribe(destination, function (response) {
          const websocketDataVo = JSON.parse(response.body);
          const messageObject = JSON.parse(websocketDataVo.content)
          me.handleMessage(messageObject)
        })
      }, () => {
        handleReject()
      });
      this.stompClient = stompClient;
      return promise;
    },
    delay(time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time);
      })
    },
    async handleFunctionEnd(data) {
      const functionResult = data.event.functionResult
      if (data.event.functionName === 'create_rulego_workflow' && functionResult.success) {
        this.$router.push({
          path: `/ruleflow/${functionResult.response.ruleChain.id}`
        })
      }
    },
    /**
* 处理消息
* @param data 消息数据服务端
* @param currentMessageItem 当前消息客户端
*/
    handleMessage(data) {
      const me = this;
      window.clearTimeout(this.receiveMessageTimer)
      console.log('handleMessage', data)
      if (typeof data?.event == 'object') {
        switch (data.event.type) {
          case 'function_start':
            if (data.event.functionName === 'start_workflow') {
              me.lockInput()
              me.isWorkflow = true
            }
            break
          case 'function_end':
            if (data.event.functionName === 'start_workflow') {
              me.isWorkflow = false
            }
            else {
              this.handleFunctionEnd(data)
            }
            break
          case 'reply-end':
            me.unlockInput()
            break
        }
      } else if (data?.isStreaming === true || data?.isCompleted === true) {
        me.handleStreamingMessage(data)
      } else {
        me.handleCoreMessage(data)
      }
      me.scrollListToEnd()
    },
    getLastMessageItem() {
      return this.messageList[this.messageList.length - 1]
    },
    updateLastMessageContent(content) {
      const lastItem = this.getLastMessageItem()
      lastItem.content = content
      this.$store.dispatch('updateLastMessage', { id: lastItem.id, content })
      this.$store.commit('addBotMessage', { id: lastItem.id, needsAnimation: true });
    },
    /*处理流消息*/
    handleStreamingMessage(data) {
      console.log('handleStreamingMessage', data)
      const item = this.getLastMessageItem()
      item.needsAnimation = false
      let content = item.content
      if (data.isCompleted === true) {
        //流结束直接覆盖
        content = data.message.content
      } else {
        //逐个token追加
        content += data.message.content
      }

      this.updateLastMessageContent(content)
    },
    /*添加消息*/
    addMessage(content) {
      this.lockInput()
      // 添加一条用户消息 和 bot 加载消息
      const userMessage = { content, createdTime: new Date(), isUser: true, id: uuidV4() };
      const botMessage = { content: '', createdTime: new Date(), isUser: false, id: uuidV4() };
      this.messageList.push(...[userMessage, botMessage]);
      this.scrollListToEnd()
      return { userMessage, botMessage }
    },
    /*核心消息处理*/
    handleCoreMessage(data) {
      const me = this;
      console.log('handleCoreMessage', data)
      switch (data?.message?.type) {
        case 'text':
          {
            if (data?.source === 'Workflow') {
              me.addMessage(data.message.content)
            } else {
              me.updateLastMessageContent(data.message.content)
            }
            if (!this.isWorkflow) {
              me.unlockInput()
            }
          }
          break
        case 'multiselect':
          {
            //   const optionsItem = new MessageModel({
            //     type: MessageType.Ai,
            //     state: MessageModelState.MultipleSelect,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(optionsItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'qrcode':
          {
            //   const optionsItem = new MessageModel({
            //     type: MessageType.Ai,
            //     state: MessageModelState.QrCode,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(optionsItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'chart': //图表
          {
            //   const chartItem = new MessageModel({
            //     type: MessageType.Ai,
            //     state: MessageModelState.Charts,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(chartItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'echarts': //echarts
          {
            //   const chartItem = new MessageModel({
            //     type: MessageType.Ai,
            //     state: MessageModelState.ECharts,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(chartItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'html': //html
          {
            //   const chartItem = new MessageModel({
            //     content: data.message.content,
            //     type: MessageType.Ai,
            //     state: MessageModelState.Html,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(chartItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'url': //html
          {
            //   const chartItem = new MessageModel({
            //     content: data.message.content,
            //     type: MessageType.Ai,
            //     state: MessageModelState.Url,
            //     rawData: data.message,
            //     creationTime: new Date(),
            //     sequenceNumber: Number(data.sequenceNumber),
            //     agentAvatar: computedAgentAvatar.value,
            //     agentName: agent.value?.name
            //   })
            //   messages.value.push(chartItem)
            //   sending.value = false
            //   selectType.value = ''
          }
          break
        case 'hidden':
          //do nothing
          break
      }
    },
    remedyMessage(inputValue, sequenceNumber) {
      this.receiveMessageTimer = setTimeout(async () => {
        try {
          let totalTime = 0;
          const interval = 500;// 0.5s间隔
          this.timer = setInterval(async () => {
            totalTime += interval;
            if (totalTime > 30 * 1000) {
              this.clearTimer();
              this.unlockInput();
            }

            const getMessageResponse = await getMessage(this.conversationId, sequenceNumber);
            const { messages } = getMessageResponse;
            if (messages.length > 1) {
              for (let i = 1; i < messages.length; i++) {
                const message = messages[i];
                if (message.sequenceNumber === sequenceNumber) {
                  continue;
                }
                this.handleMessage(message);
              }
              this.clearTimer();
            }
          }, interval);

        } catch (error) {
          this.$message.error('Error');
          this.clearTimer();
          this.unlockInput();
        }
      }, 5 * 1000)
    },
    async onSubmit() {
      if (this.inputValue === '') {
        return;
      }

      if (this.enableInput === false) {
        return;
      }

      this.$store.commit('setAllBotMessageNoAnimation');
      this.scrollListToEnd();

      const tempInput = this.inputValue;
      // 置空
      this.inputValue = '';

      // 尝试一次初始化, 失败则忽略此次发送消息
      if (!this.conversation) {
        await this.initConversation();
      }

      // this.onSendMessage(tempInput);
      await this.handleSendMessage(tempInput);
    },
    handleESC(event) {
      if (event.code === "Escape") {
        console.log('Escape');
        this.onClose();
      }
    },
    /**
     * 恢复消息列表
     */
    restoreMessageList() {
      const messages = this.$store.state.messages;
      if (messages.length > 0) {
        this.messageList.push(...this.$store.state.messages);
        // this.scrollListToEnd();//无法关闭滚动动画
      }
    },
  },
  mounted() {
    this.restoreMessageList();
  },
}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 666666;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 下面四个类是直接从 element-plus 搬过来的, 动画自己 diy 就行 */
.dialog-fade-enter-active {
  animation: dialog-fade-in 0.3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out 0.3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

.container {
  position: fixed;
  bottom: 42px;
  right: 109px;
  width: 375px;
  height: 706px;
  background: #212A3A;
  z-index: 10;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 12px 0 18px;
  height: 53px;
  font-size: 17px;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
}

header img {
  width: 27px;
  height: 27px;
}

.content {
  width: 100%;
  height: 653px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
}

.content .message-list {
  /* 撑满剩余高度 */
  flex: 1;
  overflow-y: auto;
  padding: 36px 13px 0 13px;
  scroll-behavior: smooth;
}

.bottom-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0 8px;
  border-top: 1px solid #F0F0F0;
}

input {
  width: 277px;
  height: 38px;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #D2D2D2;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

input:focus {
  outline: none;
  border-color: #FCB900;
}

.submit-button {
  width: 56px;
  height: 38px;
  border-radius: 2px;
  background-color: #FCB900;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
}

input:focus~.submit-button {
  opacity: 1;
}

.submit-button img {
  width: 22px;
  height: 22px;
  margin: 8px 17px 8px 17px;
}
</style>