<template>
    <el-scrollbar class="layout">
        <HomeTopBar />
        <HomeSecondBar />
        <div class="content-container">
            <RouterView />
        </div>
        <ChatGPT :show.sync="showChatGPT" />
        <img v-if="showChatGPTIcon" src="@/assets/ChatGPT/bottom_right_entry.png" class="bottom_right_entry" alt="" srcset="" @click="showChatGPT = true">
    </el-scrollbar>
</template>
<script>
import HomeTopBar from '@/components/HomeTopBar.vue';
import HomeSecondBar from '@/components/HomeSecondBar.vue';
import ChatGPT from '@/components/chatgpt/ChatGPT.vue';
export default {
    name: 'Layout',
    components: {
        HomeTopBar,
        HomeSecondBar,
        ChatGPT
    },
    watch: {
        $route(to) {
            if (to.name == 'ruleflow-new') {
                this.showChatGPTIcon = false;
            }
            else if (to.name == 'ruleflow-edit') {
                this.showChatGPTIcon = false;
            }
            else {
                this.showChatGPTIcon = true;
            }
        }
    },
    data() {
        return {
            currentTabComponent: "dashboard",
            showChatGPT: false,
            showChatGPTIcon: true 
        }
    }
}
</script>
<style>
.layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #212A3A;
}

.layout .el-scrollbar__wrap {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.layout .el-scrollbar__wrap .el-scrollbar__view {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    position: relative;
}

.bottom_right_entry {
    position: fixed;
    z-index: 10;
    width: 78px;
    height: 78px;
    bottom: 151px;
    right: 22px;
    cursor: pointer;
}
</style>
