export default {
  namespaced: true,
  state: () => ({
    loading: false,
    messages: JSON.parse(localStorage.getItem("rulegoChatMessages")) || [],
  }),
  mutations: {
    UPDATE_MESSAGES(state, payload) {
      state.messages = payload;
      // localStorage.setItem('rulegoChatMessages', JSON.stringify(state.messages));
    },
    UPDATE_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("UPDATE_LOADING", payload);
    },
    saveMessages({ commit }, payload) {
      commit("UPDATE_MESSAGES", payload);
    },
  },
  getters: {
    messages: (state) => state.messages,
    loading: (state) => state.loading,
  },
};
