import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import CustomMessage from "./custom-plugins/custom-message/CustomMessage.js";
import vPermission from "./directives/v-permission";
import { formatTimestamp } from "@/filters/formatTimestamp.js";

import ElementUI from "element-ui";
import VueTypedJs from "vue-typed-js";

Vue.use(ElementUI);
Vue.use(VueTypedJs);
Vue.use(CustomMessage);

Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.directive("permission", vPermission);
Vue.filter("formatTimestamp", formatTimestamp);

new Vue({
  el: "#app",
  router: router,
  store: store,
  // store,
  render: (h) => h(App),
});

console.log("v20241111build001");
